<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="list.length === 0 ? '' : '没有更多了'"
      @load="onLoad"
    >
      <!-- 空白页 -->
      <!-- 这里的v-if判断需要连同 all-order.vue - back-color类名一起判断，用于控制空白页的背景颜色 -->
      <div class="blank-page-box" v-if="list.length <= 0">
        <div class="blank-page">
          <img
            src="~@/assets/common/no_evaluation_icon_is_available.png"
            class="blank-img"
          />
          <p style="color: #bfbfc1">暂无订单~~</p>
        </div>
      </div>
      <!-- 内容 -->
      <div class="goods-content" v-else>
        <div
          class="content-card"
          v-for="(order, o_index) in list"
          :key="o_index"
        >
          <!-- 店铺名称 -->
          <div class="shop-title">
            <div class="shop-title-left" @click="goToUrl(2, order)">
              <!-- <van-icon name="shop-o" /> -->
              <img
                src="~@/assets/common/store_icon@2x.png"
                class="title-icon"
              />
              <span class="title-text">供应商：{{ order.shop_name }}</span>
              <van-icon name="arrow" />
            </div>
            <span class="is-pay">{{ order.order_status_str }}</span>
          </div>
          <!-- 商品信息 -->
          <div class="goods-info" @click="goToUrl(1, order)">
            <div class="goods-images-box">
              <!-- 样式一 -->
              <div class="goods-images" v-if="order.goods_list.length > 1">
                <div
                  class="goods-img-box"
                  v-for="(goods, g_index) in order.goods_list"
                  :key="g_index"
                >
                  <img :src="goods.goods_img" class="goods-img" />
                </div>
              </div>
              <!-- 样式二 -->
              <div class="buy-details" v-else>
                <div class="goods-img-box">
                  <img :src="order.goods_list[0].goods_img" class="goods-img" />
                </div>
                <div class="sku-info">
                  <div class="sku-info-text">
                    {{ order.goods_list[0].goods_name }}
                  </div>
                  <span class="specifications">
                    {{ order.goods_list[0].item_key }}
                  </span>
                </div>
              </div>
            </div>

            <div class="price-quantity">
              <div class="price" v-if="orderType !== 3">
                <span>￥</span>
                <span class="integer">{{ toInteger(order.total_money) }}</span>
                <span>{{ twoFloating(order.total_money) }}</span>
              </div>
              <div class="beans-integer" v-else>
                {{ toInteger(order.total_money) }}公益豆
              </div>
              <div class="quantity">共{{ order.goods_number }}件</div>
            </div>
          </div>
          <!-- 操作按钮 -->
          <div class="operation-button">
            <div
              v-if="order.order_status <= 2 && orderType * 1 !== 3 && order.order_type != 90"
              @click.stop
              @click="updateAddress(o_index)"
            >
              <van-button plain round block class="button-box">
                修改地址
              </van-button>
            </div>
            <div
              v-if="order.order_status === 1"
              @click.stop
              @click="switchFn(1, o_index)"
            >
              <van-button plain round block class="button-box">
                取消订单
              </van-button>
            </div>
            <div
              v-if="order.order_status === 1"
              @click.stop
              @click="setOrderPay(o_index, order.id)"
            >
              <van-button type="danger" plain round block class="button-box">
                立即付款
              </van-button>
            </div>
            <div
              v-if="order.order_status > 1 && order.order_status <= 4 && order.order_type != 90"
              @click.stop
              @click="goToUrl(4, order)"
            >
              <van-button plain round block class="button-box">
                退货退款
              </van-button>
            </div>
            <div
              v-if="order.order_status === 3 && order.order_type != 90"
              @click.stop
              @click="goToUrl(5, order)"
            >
              <van-button type="danger" plain round block class="button-box">
                查看物流
              </van-button>
            </div>
            <div
              v-if="order.order_status === 3 && order.order_type != 90"
              @click.stop
              @click="switchFn(2, o_index)"
            >
              <van-button type="danger" plain round block class="button-box">
                确认收货
              </van-button>
            </div>
            <div
              v-if="
                (order.order_status === 6 || order.order_status === 5) &&
                orderType !== 3 && order.order_type != 90
              "
              @click.stop
              @click="delOrder(order.id)"
            >
              <van-button plain round block class="button-box">
                删除订单
              </van-button>
            </div>
            <!-- <div
              v-if="
                (order.order_status === 6 || order.order_status === 5) &&
                orderType !== 3 && order.order_type != 90
              "
              @click.stop
              @click="addShoppingCart(order.goods_list)"
            >
              <van-button type="danger" plain round block class="button-box">
                加入购物车
              </van-button>
            </div> -->
          </div>
        </div>
      </div>
    </van-list>
    <!-- 弹出层 -->
    <!-- 请根据实际情况绑定到那个按钮 -->
    <PromptPopup
      :msg="showMsg"
      :showPopup="showPopup"
      @popupFn="popupFn"
    ></PromptPopup>
    <!--地址管理-->
    <DialogBox
      :show="showDialog"
      :msgText="msgText"
      @cancel="cancel"
      @confirm="confirm"
    ></DialogBox>
    <AddressList
      v-if="showAddress"
      @setAddressInfo="setAddressInfo"
      @closePage="closePage"
      :orderType="1"
    ></AddressList>
    <!--    订单支付-->
    <OrderPay
      v-if="showPay"
      :showPay="showPay"
      :orderType="orderType"
      :orderId="orderId"
      @endOrderPay="endOrderPay"
      @closeOverlay="closeOverlay"
    ></OrderPay>
  </div>
</template>

<script>
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import OrderPay from "@/components/pay-shade/pay-shade";
import DialogBox from "@/components/dialog-box/dialog-box";
import {
  cancelOrder,
  getOrderList,
  setDelivery,
  updateAddress,
  setDeleteOrder,
} from "@/api/order";
// import { addShopping } from "@/api/shopping.js";
import AddressList from "@/pages/my/address-list/address-list";
import { Toast } from "vant";
import tools from "@/common/js/tools";

export default {
  name: "order-card",
  components: {
    PromptPopup,
    OrderPay,
    AddressList,
    DialogBox,
  },
  props: ["orderType", "orderStatus"],
  data() {
    return {
      loading: false,
      finished: false,
      showAddress: false,
      showPopup: false,
      showPay: false,
      list: [],
      page: 1,
      total: 100,
      orderIndex: -1,
      orderId: -1,
      showMsg: "",
      showType: 1,
      isAjax: false,
      shoppingData: {},
      showDialog: false,
      msgText: "",
    };
  },
  mounted() {
    // this.startOrderList();
  },
  watch: {
    "_props.orderType": function () {
      this.startOrderList();
    },
    "_props.orderStatus": function () {
      this.startOrderList();
    },
  },
  methods: {
    onLoad() {
      this.getOrderList();
    },
    closePage() {
      this.showAddress = false;
    },
    delOrder(id) {
      this.orderId = id;
      this.msgText = "确认删除订单？";
      this.showDialog = true;
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.setDeleteOrder();
      this.showDialog = false;
    },
    // addShoppingCart(goodList) {
    //   goodList.forEach((item) => {
    //     this.shoppingData.number = item.goods_num;
    //     this.shoppingData.type = 1;
    //     this.shoppingData.spec_id = item.spec_id;
    //     this.addShopping();
    //   });
    // },
    setOrderPay(orderIndex, orderId) {
      this.orderIndex = orderIndex;
      this.orderId = orderId;
      this.showPay = true;
      this.$emit("showNav", false);
    },
    closeOverlay() {
      this.$emit("showNav", true);
      this.showPay = false;
    },
    endOrderPay(bool) {
      if (bool) {
        //支付成功
      }
      this.showPay = false;
    },
    updateAddress(orderIndex) {
      //显示修改地址
      this.showAddress = true;
      this.orderIndex = orderIndex;
    },
    async setAddressInfo(Ainfo) {
      //订单修改收货地址
      if (this.orderIndex * 1 < 0) {
        Toast.fail("订单信息错误");
        return false;
      }
      const ret = await updateAddress({
        order_id: this.list[this.orderIndex].id,
        address_id: Ainfo.id,
      });
      if (ret.code * 1 == 1) {
        Toast.success("地址信息修改成功");
      }
      this.showAddress = false;
    },
    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf("."));
      return result;
    },
    floorNum(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // 获取价格小数部分
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async setDeleteOrder(id) {
      // 删除订单
      const res = await setDeleteOrder({ order_id: this.orderId });
      if (res.code * 1 === 1) {
        Toast.success("删除成功");
        this.startOrderList();
      } else {
        Toast.fail(res.msg);
      }
    },
    // async addShopping() {
    //   // 加入购物车
    //   const res = await addShopping(this.shoppingData);
    //   if (res.code * 1 === 1) {
    //     Toast.success("加入成功");
    //     this.startOrderList();
    //   } else {
    //     Toast.fail(res.msg);
    //   }
    // },
    startOrderList() {
      //初始化订单列表
      this.list = [];
      this.page = 1;
      this.total = 100;
      this.getOrderList();
    },
    async getOrderList() {
      //获取订单列表数据
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      this.finished = false;
      const ret = await getOrderList({
        order_type: this.orderType,
        order_status: this.orderStatus,
        page: this.page,
      });
      console.log("------订单列表--------", ret.data.items);
      if (ret.code * 1 == 1) {
        this.page++;
        this.list = [...this.list, ...ret.data.items];
        this.total = ret.data.total;
      }
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
      }
      this.isAjax = false;
    },
    goToUrl(type, data) {
      //页面跳转
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({
            name: "my-order-details",
            query: { orderId: data.id, shopId: data.shop_id },
          });
          break;
        case 2:
          //店铺详情
          this.$router.push({
            name: "shop-home",
            query: { shopId: data.shop_id },
          });
          break;
        case 3:
          this.$router.push({
            name: "my-collection",
          });
          break;
        case 4:
          this.$router.push({
            name: "refund-serve",
            query: { orderId: data.id, orderStatus: data.order_status },
          });
          break;
        case 5:
          this.$router.push({
            name: "examine-logistics",
            query: { orderId: data.id },
          });
          break;
        default:
      }
    },
    switchFn(showType, orderIndex) {
      //显示提示弹框
      this.orderIndex = orderIndex;
      this.showType = showType;
      switch (showType) {
        case 1:
          this.showMsg = "是否取消此订单";
          break;
        case 2:
          this.showMsg = "确认收到货了吗？";
          break;
        default:
      }
      this.showPopup = true;
    },
    popupFn(bool) {
      // 弹框回调通知
      this.showPopup = false;
      if (bool) {
        switch (this.showType) {
          case 1:
            //执行取消订单操作
            this.cancelOrder();
            break;
          case 2:
            //执行取消订单操作
            this.setDelivery();
            break;
          default:
        }
      }
    },
    async setDelivery() {
      // 确认收货
      const ret = await setDelivery({
        order_id: this.list[this.orderIndex].id,
      });
      if (ret.code * 1 === 1) {
        Toast.success("确认收货成功");
        if (this.orderStatus * 1 === 0) {
          //修改订单状态
          this.list[this.orderIndex].order_status = 5;
          this.list[this.orderIndex].order_status_str = "已完成";
        } else {
          //删除订单数据
          this.list.splice(this.orderIndex, 1);
        }
      }
    },
    async cancelOrder() {
      // 订单取消
      const ret = await cancelOrder({
        order_id: this.list[this.orderIndex].id,
      });
      if (ret.code * 1 === 1) {
        Toast.success("订单取消成功");
        if (this.orderStatus * 1 === 0) {
          //修改订单状态
          this.list[this.orderIndex].order_status = 6;
          this.list[this.orderIndex].order_status_str = "交易关闭";
        } else {
          //删除订单数据
          this.list.splice(this.orderIndex, 1);
        }
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      let price = "";
      price = Math.floor(num * 100) / 100;
      price = String(price).split(".")[1];
      if (price !== undefined && price.length === 1) {
        price = `.${price}0`;
      } else {
        price === undefined ? (price = ".00") : (price = `.${price}`);
      }

      return price;
    },
  },
};
</script>

<style lang="less" scoped>
// 空白页
.blank-page-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-color: #fff;

  .blank-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 194px;
    text-align: center;
    .blank-img {
      display: block;
      width: 100%;
    }
  }
}

// 订单卡片样式
.goods-content {
  margin-top: 12px;
}

.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;

  .shop-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shop-title-left {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #c6c6c6;
      .title-icon {
        display: block;
        width: 21px;
        height: 21px;
      }
      .title-text {
        width: 200px;
        font-size: 18px;
        margin: 0 4px;
        font-weight: 500;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .is-pay {
      color: #ed301d;
      font-size: 17px;
    }
  }
}

// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;

  .goods-images-box {
    overflow-x: scroll;
  }

  // 样式一
  .goods-images {
    display: flex;
  }

  .goods-img-box {
    flex-shrink: 0;
    width: 90px;
    padding: 0 5px;
    img {
      width: 90px;
      height: 90px;
    }
    .goods-img {
      display: block;
      width: 100%;
      border-radius: 6px;
    }
  }

  // 样式二
  .buy-details {
    display: flex;
    justify-content: center;
    width: 100%;
    .sku-info {
      margin-left: 12px;
      padding: 10px 0;
      width: 100%;
      .sku-info-text {
        flex-shrink: 0;
        width: 145px;
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 20px;
      }

      .specifications {
        max-width: 145px;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 4px 12px;
        margin-right: 10px;
        font-size: 14px;
        color: #6f6f6f;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80px;
    .price {
      font-size: 14px;
      .integer {
        font-size: 18px;
      }
      .price-after {
        font-size: 14px;
      }
    }
    .beans-integer {
      font-size: 17px;
    }
    .quantity {
      margin-top: 12px;
      color: #aaaaaa;
      font-size: 17px;
    }
  }
}

// 操作按钮
.van-button {
  width: 90px;
  height: 40px;
}

.operation-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
.button-box {
  width: 93px;
  height: 39px;
  padding: 0 !important;
  margin-left: 12px;
}
</style>
