<template>
  <van-popup v-model="show" round>
    <div class="dialog-box">
      <span class="dialog-title">{{ msgText }}</span>
      <div class="buttons">
        <van-button class="button" @click="cancel" round>{{
          cancelText
        }}</van-button>
        <van-button class="button" round type="danger" @click="confirm">
          {{ confirmText }}
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "dialog-box",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    confirmText: {
      type: String,
      default: "确定",
    },
    msgText: {
      type: String,
      default: "您确定执行此操作吗？",
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  width: 116px;
  height: 39px;
  padding: 0;
}
.dialog-box {
  width: 100%;
}
.dialog-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 34px;
  font-size: 18px;
}
.van-popup[data-v-99eeeaa2] {
  width: 323px;
  height: 194px;
  padding: 46px 39px;
}
.van-button__text {
  font-size: 18px;
}
</style>